export const COLOR = {
  CORAL_MAIN: '#FF6262',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  PINK: '#FF4B77',
  YELLOW: '#FFB72B',
  BLUE: '#4C48FF',
  PINK_LIGHT_SUB: '#FF5D84',
  CORAL_SUB: '#FF806F',
  CORAL_1: '#FFF0F0',
  GREEN_LIGHT_SUB: '#41E897',
  GREEN_SUB: '#3ACE90',
  SKYBLUE_SUB: '#42A4FF',
  PURPLE_SUB: '#8E58FF',
  GRAY_SUB: '#444444',
  GRAY_1: '#F8F8F8',
  GRAY_15: '#F5F5F5',
  GRAY_2: '#EFEFEF',
  GRAY_3: '#E9E9E9',
  GRAY_35: '#DEDEDE',
  GRAY_4: '#BEBEBE',
  GRAY_5: '#909090',
  GRAY_6: '#606060',
  GRAY_7: '#434343',
  GRAY_8: '#2B2B2B',
};
